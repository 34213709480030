import React, { useRef } from "react";
import "./Contact.css";
import SQL from "../../assets/SQL.png";
import Python from "../../assets/python.png";
import AWS from "../../assets/AWS.png";
import Snowflake from "../../assets/Snowflake_2.png";
import Java from "../../assets/java.png";
import Databricks from "../../assets/Databricks.png";
import ReactIcon from "../../assets/react.png";
import GithubIcon from "../../assets/github.png";
import TwitterIcon from "../../assets/twitter.png";
import LinkedinIcon from "../../assets/linkedin.png";
import InstagramIcon from "../../assets/instagram.png";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
export const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rg4ua1o",
        "template_kr86raa",
        form.current,
        "YUSWLCszYKeWk5-YF"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          swal("I have received the details. I will contact you soon!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contactPage">
      <div id="clients">
        <h1 className="contactPageTitle_two">Skillset</h1>
        <div className="clientImgs">
          <img src={SQL} alt="" className="clientImg" />
          <img src={Python} alt="" className="clientImg" />
          <img src={Java} alt="" className="clientImg" />
          <img src={AWS} alt="" className="clientImg" />
          <img src={Databricks} alt="" className="clientImg" />
          <img src={Snowflake} alt="" className="clientImg" />
          <img src={ReactIcon} alt="" className="clientImg" />
        </div>
      </div>
      <div id="contact">
        <h1 className="contactPagetTitle">Contact Me</h1>
        <span className="contactDesc">
          Please fill out the form below to discuss any work opportunities.
        </span>
        <form className="contactForm" ref={form} onSubmit={sendEmail} netlify>
          <input
            className="name"
            type="text"
            placeholder="Your Name"
            name="your_name"
            required
          />
          <input
            className="email"
            type="email"
            placeholder="Your Email"
            name="your_email"
            required
          />
          <textarea
            name="message"
            rows="5"
            placeholder="Your Message"
            className="msg"
            required
          ></textarea>
          <button type="submit" className="submitBtn">
            Submit
          </button>
          <div className="links">
            <a href="https://www.linkedin.com/in/krishnasn/">
              <img className="link" alt="Linkedin" src={LinkedinIcon} />
            </a>
            <a href=" https://github.com/KrishnaSn">
              <img className="link" alt="Github" src={GithubIcon} />
            </a>
            <a href="https://twitter.com/krishna15781875">
              <img className="link" alt="Twitter" src={TwitterIcon} />
            </a>
            <a href="https://www.instagram.com/krishna_sn_/">
              <img className="link" alt="Instagram" src={InstagramIcon} />
            </a>
          </div>
        </form>
      </div>
    </section>
  );
};
