import React from "react";
import "./Skills.css";
import DataModel from "../../assets/Data_Model.png";
import DataEngineering from "../../assets/Data_Engineering.png";
import CloudComputing from "../../assets/Cloud_Computing.png";
export const Skills = () => {
  return (
    <section id="skills">
      <span className="skillTitle">What I do</span>
      <span className="skillDesc">
        I am a proactive and innovative Data engineer with 2.6
        years of experience, fortified with esteemed AWS and Data Bricks
        certifications. My unwavering dedication is geared toward delivering
        concrete and measurable results. With a well-documented track record of
        achievements.
      </span>
      <div className="skillBars">
        <div className="skillBar">
          <img
            className="skillBarImg"
            src={DataEngineering}
            alt="DataEngineering"
          />
          <div className="skillBarText">
            <h2>Data Engineering</h2>
            <p>
              Specializing in data engineering, with a focus on streamlining
              data processes, ensuring data integrity, and harnessing the full
              potential of data for informed decision-making.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img className="skillBarImg" src={DataModel} alt="DataModel" />
          <div className="skillBarText">
            <h2>Data Modelling</h2>
            <p>
              Specializing in data modeling with a focus on extracting
              meaningful insights and optimizing data structures.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img
            className="skillBarImg"
            src={CloudComputing}
            alt="CloudComputing"
          />
          <div className="skillBarText">
            <h2>Cloud Compute</h2>
            <p>
              Specializing in cloud computing, with a focus on leveraging cloud
              services to drive efficiency, scalability, and innovation in data
              management and processing.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
