import React from "react";
import "./Intro.css";
import bg from "../../assets/Intro.jpg";
import { Link } from "react-scroll";
import btnImg from "../../assets/hireme.png";
export const Intro = () => {
  return (
    <section id="intro">
      <div className="introContent">
        <span className="hello">Hello,</span>
        <span className="introText">
          I'm <span className="introName">Krishna Nandihal</span>
          <br />
          Data Engineer
        </span>
        <p className="introPara">
          I'm a Skilled Data Engineer with Expertise
          <br />
          in Data Engineering,
          <br />
          Backed by AWS and Databricks Certifications{" "}
        </p>
        <Link>
          <button
            className="btn"
            onClick={() => {
              document
                .getElementById("contact")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            <img className="btnImg" src={btnImg} alt="Hire me"></img>Hire me
          </button>
        </Link>
      </div>
      <img src={bg} alt="" className="bg" />
    </section>
  );
};
