import React from "react";
import "./Works.css";
import DataEngineering2 from "../../assets/Data_Engineering_2.png";
import Freelance from "../../assets/freelancer.png";
import Resume from "../../assets/Krishna_Nandihal_Resume.pdf";

export const Works = () => {
  return (
    <section id="works">
      <h2 className="workstitle">My Experience</h2>
      <div className="expBars">
        <div className="expBar">
          <img
            className="expBarImg"
            src={DataEngineering2}
            alt="DataEningeering"
          />
          <div className="expText">
            <h2>Data Engineer at Cognizant</h2>
            <span className="expSpan">(August 2021 – Present)</span>
            <p className="expPara">
              • Ingested retail data from multiple sources into Snowflake, enabling seamless integration.
              <br />
              • Unified disparate datasets for streamlined analysis, driving actionable insights for retail operations.
              <br />• Automated migration workflows using WhereScape, achieving 75% efficiency in Snowflake data warehouse maintenance.
              <br />
              • Developed complex ETL processes, increasing data accuracy and integrity by 75%, using SQL and Python.
              <br />
              • Improved data reliability by 20% through setting up AWS pipelines and jobs, monitoring and resolving job failures promptly.
              <br />
              • Engineered efficient Spark code in Databricks, enhancing data processing speed by 50% and overall accuracy.
              <br />
              • Collaborated with 4 data engineers and database administrators in an agile environment, conducting peer reviews to enhance coding standards.
              <br />
              • Mentored 2 interns through on-boarding orientation and training sessions.
            </p>
          </div>
        </div>
        <div className="expBar">
          <img className="expBarImg" src={Freelance} alt="Freelance" />
          <div className="expText">
            <h2>Graphic Designer </h2>
            <span className="expSpan">(August 2015 - August 2017)</span>
            <p className="expPara">
              • Collaborated closely with clients to craft compelling Streaming
              Overlay Packages.
              <br />
              • Cultivated a robust online presence on Behance.net, accumulating
              over 90k+ project views and 2000+ appreciations.
              <br />
            </p>
          </div>
        </div>
      </div>
      <a href={Resume} download="Krishna Nandihal Data Engineering" target="_blank" rel="noopener noreferrer">
        <button className="workBtn">Download Resume</button>
      </a>
    </section>
  );
};
