import React from "react";
import "./Footer.css";
export const Footer = () => {
  return (
    <footer className="footer">
      <p className="footerText">
        This website was created by <span>Krishna Nandihal</span>
      </p>
      Copyright &#169;2023 Krishna Nandihal. All right received.
    </footer>
  );
};
